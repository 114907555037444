<template>
  <div class="d-flex flex-wrap align-items-start">
    <div class="vb__utils__avatar vb__utils__avatar--size110 mr-4">
      <img src="resources/images/avatars/avatar-2.png" alt="logo sig" />
    </div>
    <div>
      <div class="text-uppercase font-size-15">
        Role Sebagai:
        <pre>{{ roles.map(r => r.role).join(', ') }}</pre>
      </div>
      <div class="text-dark font-size-15">
        Group Area :
        <pre>{{ roles.map(r => r.group_role).join(', ') }}</pre>
      </div>
      <div class="text-dark font-size-15">
        IP Address :
        <pre>{{ ip }}</pre>
      </div>
      <div class="text-dark font-size-15">
        Terakhir Login :
        <pre>{{ last_login }}</pre>
      </div>
    </div>
  </div>
</template>
<script>
// kopi dari widgets-general 10
// import store from '@/store'
import { computed } from 'vue'
import { useStore } from 'vuex'
import store from 'store'
import moment from 'moment'

const profile = store.get('profile')

export default {
  name: 'UserInfo',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    const roles = profile.roles
    const ip = profile.ip
    const last_login = moment(profile.last_login).format('DD MMMM YYYY, HH:mm:ss')

    return {
      settings,
      profile,
      roles,
      ip,
      last_login,
    }
  },

  // computed: {
  //   roles() {
  //     return this.$store.state.user.roles
  //   },
  //   ip() {
  //     return this.$store.state.user.ip
  //   },
  //   last_login() {
  //     const last_login = moment(this.$store.state.user.last_login).format('DD MMMM YYYY, HH:mm:ss')
  //     return last_login
  //   },
  // },

}
</script>
<style lang="scss" module></style>
